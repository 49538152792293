export interface Drink {
    id: string;
    name: string;
    image: string;
    ingredients: Ingredient[];
    instructions: string;
    glass: string;
    category: string;
    alcoholic: boolean;
    tags: string[];
}

interface Ingredient {
    name: string;
    measure: string;
}

export const parseDrink = (data: any): Drink => {
    const ingredients: Ingredient[] = [];

    for (let i = 1; i < 15; i++) {
        const iName = data[`strIngredient${i}`];
        const iMeasure = data[`strMeasure${i}`];

        if (iName) {
            ingredients.push({ name: iName, measure: iMeasure });
        }
    }

    return {
        id: data.idDrink,
        name: data.strDrink,
        image: data.strDrinkThumb,
        ingredients,
        instructions: data.strInstructions,
        glass: data.strGlass,
        category: data.strCategory,
        alcoholic: data.strAlcoholic === "Alcoholic",
        tags: data.strTags?.split(",") ?? [],
    };
};
