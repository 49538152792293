import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDrinkContext } from "../../context/drink";
import { Icon, IconTypes } from "../../components/icon";

import "./item.scss";

export const Item: FC = () => {
    const { id = "" } = useParams();
    const { drinks, getById } = useDrinkContext();

    const current = drinks[id];

    useEffect(() => {
        if (id.length > 0 && !drinks[id]) {
            console.log({ id });
            getById(id);
        }
    }, [id]);

    if (!current) {
        return null;
    }

    return (
        <>
            <section className="metadata">
                <div className="metadata-inner">
                    <h1>{current.name}</h1>
                    <div className="metadata-info">
                        <InfoItem
                            icon="alcoholic"
                            value={`${
                                current.alcoholic
                                    ? "Alcoholic"
                                    : "Not alcoholic"
                            }`}
                        />
                        <InfoItem
                            icon={determineGlass(current.glass)}
                            value={current.glass}
                        />
                        <InfoItem icon="category" value={current.category} />
                    </div>
                </div>
            </section>
            <section className="details">
                <pre>{JSON.stringify(current, null, 4)}</pre>
            </section>
        </>
    );
};

interface InfoItemProps {
    icon: IconTypes;
    value: string;
}

const determineGlass = (glassType: string): IconTypes => {
    switch (glassType.toLowerCase()) {
        case "cocktail glass":
            return "glassCocktail";
        case "martini glass":
            return "glassMartini";
        case "margarita glass":
            return "glassCitrus";
        case "wine glass":
        case "white wine glass":
            return "glassWine";
        case "champagne flute":
            return "glassChampagne";
        case "coffee mug":
        case "irish coffee cup":
            return "coffeeMug";
        case "whiskey glass":
        case "brandy snifter":
        case "whiskey sour glass":
            return "glassWhiskey";
        default:
            return "glassDefault";
    }
};

const InfoItem: FC<InfoItemProps> = ({ icon, value }) => (
    <div className="metadata-info-item">
        <Icon name={icon} size="large" />
        <span>{value}</span>
    </div>
);
