import { FC } from "react";
import classNames from "classnames";

import { SVG, SVGTypes } from "./svg";

import "./icon.scss";

export interface IconProps {
    name: SVGTypes;
    size?: "small" | "large";
}

export const Icon: FC<IconProps> = ({ name, size = "small" }) => (
    <SVG name={name} className={classNames("icon", `icon-${size}`)} />
);

export type { SVGTypes as IconTypes };
