import { FC, PropsWithChildren, useEffect, useState } from "react";
import axios from "axios";

import { Drink, parseDrink } from "../../types/Drink";
import {
    DrinkContext,
    DrinkMap,
    DrinkMeta,
    DrinkMetaKey,
    initialDrinkContext,
} from "./context";
import { baseUrl } from "../../constants";

interface DrinkContextProviderProps extends PropsWithChildren {}

export const DrinkContextProvider: FC<DrinkContextProviderProps> = ({
    children,
}) => {
    const [drinks, setDrinks] = useState<DrinkMap>(initialDrinkContext.drinks);
    const [results, setResults] = useState<Drink[]>(
        initialDrinkContext.results
    );
    const [meta, setMeta] = useState<DrinkMeta>(initialDrinkContext.meta);

    const loadMeta = () => {
        const getMeta = (metaKey: DrinkMetaKey) => {
            axios
                .get(`${baseUrl}/list.php?${metaKey}=list`)
                .then(({ data }) => {
                    if (data && data.drinks) {
                        const keyIndex = Object.values(DrinkMetaKey).indexOf(
                            metaKey as unknown as DrinkMetaKey
                        );
                        const key =
                            Object.keys(DrinkMetaKey)[keyIndex].toLowerCase();

                        const meta = data.drinks.map(
                            (d: any) => Object.values(d)[0]
                        );

                        setMeta((prevState) => ({
                            ...prevState,
                            [key]: meta,
                        }));
                    }
                });
        };

        getMeta(DrinkMetaKey.CATEGORIES);
        getMeta(DrinkMetaKey.GLASSES);
        getMeta(DrinkMetaKey.INGREDIENTS);
    };

    const updateDrinkMap = (array: Drink[]) => {
        const drinkMap: DrinkMap = {};
        array.forEach((drink) => (drinkMap[drink.id] = drink));
        setDrinks((prevState) => ({
            ...prevState,
            ...drinkMap,
        }));
    };

    const queryDB = (keyword: string = ""): Promise<Drink[]> => {
        return axios
            .get(`${baseUrl}/search.php?s=${keyword}`)
            .then(({ data }) => {
                if (data && data.drinks) {
                    const array: Drink[] = data.drinks.map(parseDrink);
                    updateDrinkMap(array);
                    return array;
                }
                return [];
            })
            .catch((err) => {
                console.error(err);
                return [];
            });
    };

    const search = (keyword: string) => queryDB(keyword).then(setResults);

    const getById = (id: string) => {
        axios.get(`${baseUrl}/lookup.php?i=${id}`).then(({ data }) => {
            const drink = parseDrink(data.drinks[0]);
            updateDrinkMap([drink]);
        });
    };

    useEffect(() => {
        loadMeta();
        queryDB();
    }, []);

    return (
        <DrinkContext.Provider
            value={{ drinks, results, meta, search, getById }}
        >
            {children}
        </DrinkContext.Provider>
    );
};
