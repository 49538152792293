/*
*   DO NOT MAKE CHANGES TO THIS FILE
*   THIS FILE WILL BE OVERWRITTEN
*
*   GENERATED BY REACT-SVGS
*   https://github.com/wllkle/react-svgs.git
*/

type Attributes = Record<string, string | object>;

export interface INode {
    name: string,
    type: string,
    value?: string,
    attributes?: Attributes,
    children?: INode[]
}

interface ISVGData {
    [key: string]: {
        name: string,
        attributes?: Attributes,
        children: INode[]
    }
}

export const data: ISVGData = {
    alcoholic: {
        name: "alcoholic",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 512 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M507.31 72.57L439.43 4.69c-6.25-6.25-16.38-6.25-22.63 0l-22.63 22.63c-6.25 6.25-6.25 16.38 0 22.63l-76.67 76.67c-46.58-19.7-102.4-10.73-140.37 27.23L18.75 312.23c-24.99 24.99-24.99 65.52 0 90.51l90.51 90.51c24.99 24.99 65.52 24.99 90.51 0l158.39-158.39c37.96-37.96 46.93-93.79 27.23-140.37l76.67-76.67c6.25 6.25 16.38 6.25 22.63 0l22.63-22.63c6.24-6.24 6.24-16.37-.01-22.62zM179.22 423.29l-90.51-90.51 122.04-122.04 90.51 90.51-122.04 122.04z"
                }
            }
        ]
    },
    category: {
        name: "category",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 512 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M0 252.118V48C0 21.49 21.49 0 48 0h204.118a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882L293.823 497.941c-18.745 18.745-49.137 18.745-67.882 0L14.059 286.059A48 48 0 0 1 0 252.118zM112 64c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"
                }
            }
        ]
    },
    coffeeMug: {
        name: "coffee-mug",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 640 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z"
                }
            }
        ]
    },
    glassBeer: {
        name: "glass-beer",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 448 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M368 96h-48V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56v400c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24v-42.11l80.606-35.977C429.396 365.063 448 336.388 448 304.86V176c0-44.112-35.888-80-80-80zm16 208.86a16.018 16.018 0 0 1-9.479 14.611L320 343.805V160h48c8.822 0 16 7.178 16 16v128.86zM208 384c-8.836 0-16-7.164-16-16V144c0-8.836 7.164-16 16-16s16 7.164 16 16v224c0 8.836-7.164 16-16 16zm-96 0c-8.836 0-16-7.164-16-16V144c0-8.836 7.164-16 16-16s16 7.164 16 16v224c0 8.836-7.164 16-16 16z"
                }
            }
        ]
    },
    glassChampagne: {
        name: "glass-champagne",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 256 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M216 464h-56V347.7c60.7-15.2 103.3-72 95-135.4L228 27.4C225.7 11.7 212.2 0 196.3 0H59.7C43.8 0 30.3 11.7 28 27.4L1 212.3c-8.3 63.4 34.3 120.2 95 135.4V464H40c-22.1 0-40 17.9-40 40 0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8 0-22.1-17.9-40-40-40zM61.8 128l11.7-80h109l11.7 80H61.8z"
                }
            }
        ]
    },
    glassCheers: {
        name: "glass-cheers",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 640 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M639.4 433.6c-8.4-20.4-31.8-30.1-52.2-21.6l-22.1 9.2-38.7-101.9c47.9-35 64.8-100.3 34.5-152.8L474.3 16c-8-13.9-25.1-19.7-40-13.6L320 49.8 205.7 2.4c-14.9-6.2-32-.3-40 13.6L79.1 166.5C48.9 219 65.7 284.3 113.6 319.2L74.9 421.1l-22.1-9.2c-20.4-8.5-43.7 1.2-52.2 21.6-1.7 4.1.2 8.8 4.3 10.5l162.3 67.4c4.1 1.7 8.7-.2 10.4-4.3 8.4-20.4-1.2-43.8-21.6-52.3l-22.1-9.2L173.3 342c4.4.5 8.8 1.3 13.1 1.3 51.7 0 99.4-33.1 113.4-85.3l20.2-75.4 20.2 75.4c14 52.2 61.7 85.3 113.4 85.3 4.3 0 8.7-.8 13.1-1.3L506 445.6l-22.1 9.2c-20.4 8.5-30.1 31.9-21.6 52.3 1.7 4.1 6.4 6 10.4 4.3L635.1 444c4-1.7 6-6.3 4.3-10.4zM275.9 162.1l-112.1-46.5 36.5-63.4 94.5 39.2-18.9 70.7zm88.2 0l-18.9-70.7 94.5-39.2 36.5 63.4-112.1 46.5z"
                }
            }
        ]
    },
    glassCitrus: {
        name: "glass-citrus",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 512 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M368 0c-62.61 0-115.35 40.2-135.18 96h52.54C302 67.45 332.63 48 368 48a96.11 96.11 0 0 1 96 96c0 50.07-38.67 90.84-87.63 95.15l-4.84 48.49C449.39 285.73 512 222.32 512 144A144 144 0 0 0 368 0zm-48 128H32A32 32 0 0 0 .16 163.18l32 320A32 32 0 0 0 64 512h224a32 32 0 0 0 31.84-28.82l32-320A32 32 0 0 0 320 128zm-41.76 128H73.76l-6.4-64h217.28z"
                }
            }
        ]
    },
    glassCocktail: {
        name: "glass-cocktail",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 576 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M296 464h-56V338.78l168.74-168.73c15.52-15.52 4.53-42.05-17.42-42.05H24.68c-21.95 0-32.94 26.53-17.42 42.05L176 338.78V464h-56c-22.09 0-40 17.91-40 40 0 4.42 3.58 8 8 8h240c4.42 0 8-3.58 8-8 0-22.09-17.91-40-40-40zM432 0c-62.61 0-115.35 40.2-135.18 96h52.54c16.65-28.55 47.27-48 82.64-48 52.93 0 96 43.06 96 96s-43.07 96-96 96c-14.04 0-27.29-3.2-39.32-8.64l-35.26 35.26C379.23 279.92 404.59 288 432 288c79.53 0 144-64.47 144-144S511.53 0 432 0z"
                }
            }
        ]
    },
    glassDefault: {
        name: "glass-default",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 384 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M354 0H34A32 32 0 0 0 2.06 34l32 448A32 32 0 0 0 66 512h256a32 32 0 0 0 31.94-30l32-448A32 32 0 0 0 354 0zm-34.37 64l-6.86 96H75.22l-6.86-96z"
                }
            }
        ]
    },
    glassMartini: {
        name: "glass-martini",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 512 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M502.05 57.6C523.3 36.34 508.25 0 478.2 0H33.8C3.75 0-11.3 36.34 9.95 57.6L224 271.64V464h-56c-22.09 0-40 17.91-40 40 0 4.42 3.58 8 8 8h240c4.42 0 8-3.58 8-8 0-22.09-17.91-40-40-40h-56V271.64L502.05 57.6z"
                }
            }
        ]
    },
    glassWhiskey: {
        name: "glass-whiskey",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 512 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M480 32H32C12.5 32-2.4 49.2.3 68.5l56 356.5c4.5 31.5 31.5 54.9 63.4 54.9h273c31.8 0 58.9-23.4 63.4-54.9l55.6-356.5C514.4 49.2 499.5 32 480 32zm-37.4 64l-30 192h-313L69.4 96h373.2z"
                }
            }
        ]
    },
    glassWine: {
        name: "glass-wine",
        attributes: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 288 512"
        },
        children: [
            {
                name: "path",
                type: "element",
                attributes: {
                    d: "M216 464h-40V346.81c68.47-15.89 118.05-79.91 111.4-154.16l-15.95-178.1C270.71 6.31 263.9 0 255.74 0H32.26c-8.15 0-14.97 6.31-15.7 14.55L.6 192.66C-6.05 266.91 43.53 330.93 112 346.82V464H72c-22.09 0-40 17.91-40 40 0 4.42 3.58 8 8 8h208c4.42 0 8-3.58 8-8 0-22.09-17.91-40-40-40z"
                }
            }
        ]
    }
};

export type SVGTypes = "alcoholic" |
	"category" |
	"coffeeMug" |
	"glassBeer" |
	"glassChampagne" |
	"glassCheers" |
	"glassCitrus" |
	"glassCocktail" |
	"glassDefault" |
	"glassMartini" |
	"glassWhiskey" |
	"glassWine";

export const SVGTypesArray: SVGTypes[] = [
    "alcoholic",
	"category",
	"coffeeMug",
	"glassBeer",
	"glassChampagne",
	"glassCheers",
	"glassCitrus",
	"glassCocktail",
	"glassDefault",
	"glassMartini",
	"glassWhiskey",
	"glassWine"
];
