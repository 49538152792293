import React from "react";
import ReactDOM from "react-dom/client";

import { App } from "./app";
import { DrinkContextProvider } from "./context/drink/provider";

import "reset-css/reset.css";
import "./app.scss";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <DrinkContextProvider>
            <App />
        </DrinkContextProvider>
    </React.StrictMode>
);
