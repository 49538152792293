import { FC } from "react";
import { Link } from "react-router-dom";

import { Drink } from "../../types/Drink";

import "./drink-list.scss";

export interface DrinkListProps {
    data: Drink[];
}

export const DrinkList: FC<DrinkListProps> = ({ data }) => (
    <section className="drink-list">
        {data.map((drink, index) => (
            <Link
                to={`/drinks/${drink.id}`}
                className="drink-list-item"
                key={index}
            >
                <img src={drink.image} alt={drink.name} />
                {drink.name}
            </Link>
        ))}
    </section>
);
