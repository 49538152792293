import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useDrinkContext } from "../../context/drink";
import { DrinkList } from "../../components/drink-list";
import { SearchBar } from "../../components/search-bar";

import "./search.scss";

export const Search = () => {
    const [queryParams, setQueryParams] = useSearchParams();
    const { results, search } = useDrinkContext();

    useEffect(() => {
        search(queryParams.get("s") ?? "");
    }, [queryParams]);

    return (
        <>
            <section className="search">
                <div className="search-inner">
                    <SearchBar
                        value={queryParams.get("s") ?? ""}
                        onChange={(value) => {
                            queryParams.set("s", value);
                            setQueryParams(queryParams);
                        }}
                    />
                </div>
            </section>
            <DrinkList data={results} />
        </>
    );
};
