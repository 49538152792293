import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDrinkContext } from "../../context/drink";
import { DrinkList } from "../../components/drink-list";
import { SearchBar } from "../../components/search-bar";

import "./home.scss";

export const Home: FC = () => {
    const navigate = useNavigate();
    const { drinks, meta } = useDrinkContext();
    const [searchValue, setSearchValue] = useState<string>("");

    const goToSearch = () => {
        navigate(`/search?s=${searchValue}`);
    };

    return (
        <>
            <section className="hero">
                <div className="hero-image" />
                <div className="hero-inner">
                    <h1>Find your next cocktail.</h1>
                    <SearchBar
                        value={searchValue}
                        onChange={setSearchValue}
                        onEnter={goToSearch}
                    />
                </div>
            </section>
            <DrinkList data={Object.values(drinks)} />
        </>
    );
};
