import { FC } from "react";

import "./search-bar.scss";

export interface SearchBarProps {
    value: string;
    onChange: (value: string) => any;
    onEnter?: () => any;
}

export const SearchBar: FC<SearchBarProps> = ({ value, onChange, onEnter }) => (
    <input
        type="search"
        className="search-bar"
        value={value}
        placeholder="Search"
        autoFocus
        onChange={(e) => onChange(e.target.value)}
        onKeyUp={(e) => {
            if (onEnter && e.key === "Enter" && value.length > 0) onEnter();
        }}
    />
);
