import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Navbar } from "./components/navbar";
import { Home } from "./pages/home";
import { Search } from "./pages/search";
import { Item } from "./pages/item";

export const App = () => {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="" element={<Home />} />
                <Route path="search" element={<Search />} />
                <Route path="drinks/:id" element={<Item />} />
            </Routes>
        </Router>
    );
};
