import { Link } from "react-router-dom";

import { Icon } from "../icon";

import "./navbar.scss";

export const Navbar = () => (
    <header>
        <nav className="navbar">
            <Link to="/">
                <Icon name="glassCheers" />
            </Link>
        </nav>
    </header>
);
