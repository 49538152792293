import { createContext } from "react";

import { Drink } from "../../types/Drink";

export interface DrinkMap {
    [key: string]: Drink;
}

export enum DrinkMetaKey {
    CATEGORIES = "c",
    GLASSES = "g",
    INGREDIENTS = "i",
}

export interface DrinkMeta {
    categories: string[];
    glasses: string[];
    ingredients: string[];
}

interface DrinkContextType {
    drinks: DrinkMap;
    results: Drink[];
    meta: DrinkMeta;
    search: (keyword: string) => void;
    getById: (id: string) => void;
}

export const initialDrinkContext: DrinkContextType = {
    drinks: {},
    results: [],
    meta: {
        categories: [],
        glasses: [],
        ingredients: [],
    },
    search: () => {},
    getById: (id) => {},
};

export const DrinkContext =
    createContext<DrinkContextType>(initialDrinkContext);
